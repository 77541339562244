import * as React from "react"
import { Link } from "gatsby"

import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import Layout from "../components/layout"

const ContactUs = () => {
  return (
    <Layout>
      <Header />
      <>
        <Seo title="Contact Us" />
        <div className="container project-gallery">
          <div className="contact-us my-4 my-md-5">
            <h1 className="project-gallery-heading mars-blue mars-centra-bold-font border-left-mars-teal text-start my-3 my-md-4 ps-2">
              <b>Contact Us</b>
            </h1>
            <div className="content">
              <p>
                For build or support a promotional site please submit your
                request{" "}
                <a
                  href="https://forms.office.com/Pages/ResponsePage.aspx?id=ND7BLz_wi0mYKny0RuJbxv9yaDkRB1FBnA-MnVlHRx5URDVBN1dQVUdKUTQ0MzFRRUpYTFZYTFhHTC4u"
                  target="_blank"
                  rel="noreferrer"
                  className="text-decoration-none mars-centra-bold-font cursor-pointer"
                >
                  here
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </>
      <Footer contactus={true} />
    </Layout>
  )
}

export default ContactUs
